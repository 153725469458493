// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
global.$ = require('jquery')

import { tns } from "tiny-slider"
import 'micromodal';
import wow from 'wow.js/dist/wow.min.js';
import './announcements.js'

global.wow = wow;
global.tns = tns;
